import Image1 from '../../assets/testimonial1.png';
import Image2 from '../../assets/testimonial2.jpg';
import Image3 from '../../assets/testimonial3.jpg';
import Image4 from '../../assets/testimonial2.png';

export const Data = [
    {
      id: 1,
      image: Image1,
      title: "Matheesha Pathirana",
      description:
        "Sathmin Januth always help to develop my projects and working with him is fun. As a desktop application developer I really appreciate Sathmin Januth's hard work!",
    },
    {
      id: 2,
      image: Image2,
      title: "Tharuka Henuka",
      description:
        "Sathmin : innocent and a humble person if u look at it from personality wise, knows how to help everyonr in a group project and gives equal involvement and support.",
    },
    {
      id: 3,
      image: Image3,
      title: "Sanupa Ranmeth",
      description:
        "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
      id: 4,
      image: Image4,
      title: "Bihadu Netmika",
      description:
        "A really good job, all aspects of the project were followed step by step and with good results.",
    },
  ];